const MODULE_AUTH = 'AUTH';
const MODULE_APP = 'APP';
const MODULE_PAGE = 'PAGE';
const MODULE_API = 'API';

/* AUTH */
export const SET_AUTHENTICATED_STATE = `${MODULE_AUTH}/SET_AUTHENTICATED_STATE`;
export const SET_USER = `${MODULE_AUTH}/SET_USER`;

/* APP */
export const SET_SCSS_VARIABLES = `${MODULE_APP}/SET_SCSS_VARIABLES`;
export const SET_MOBILE_VIEW_WIDTH_STATE = `${MODULE_APP}/SET_MOBILE_VIEW_WIDTH_STATE`;
export const SET_CTM_CHAT_LOADING_STATE = `${MODULE_APP}/SET_CTM_CHAT_LOADING_STATE`;
export const SET_VIEWER_DEVICE = `${MODULE_APP}/SET_VIEWER_DEVICE`;
export const SET_MOBILE_HEADER_NAV_ACTIVITY_STATE = `${MODULE_APP}/SET_MOBILE_HEADER_NAV_ACTIVITY_STATE`;
export const SET_USER_AGENT = `${MODULE_APP}/SET_USER_AGENT`;
export const SET_OA_PLATFORM = `${MODULE_APP}/OA_PLATFORM`;
export const SET_SERVER_COOKIES = `${MODULE_APP}/SET_SERVER_COOKIES`;
export const SET_DESKTOP_REQUESTED_STATE = `${MODULE_APP}/SET_DESKTOP_REQUESTED_STATE`;
export const SET_FIREBASE_LOADED_STATE = `${MODULE_APP}/SET_FIREBASE_LOADED_STATE`;
export const SET_FIREBASE_REQUIRED_STATE = `${MODULE_APP}/SET_FIREBASE_REQUIRED_STATE`;
export const SET_LOADING_STATE = `${MODULE_APP}/SET_LOADING_STATE`;
export const SET_CLIENT_WIDTH = `${MODULE_APP}/SET_CLIENT_WIDTH`;
export const SET_CTM_NUMBER = `${MODULE_APP}/SET_CTM_NUMBER`;
export const SET_BOOT_STATUS = `${MODULE_APP}/SET_BOOT_STATUS`;

/* PAGE */
export const SET_PAGES = `${MODULE_PAGE}/SET_PAGES`;
export const SET_SLUG = `${MODULE_PAGE}/SET_SLUG`;
export const SET_REFERRER = `${MODULE_PAGE}/SET_REFERRER`;

/* API */
export const SET_API_STATUS = `${MODULE_API}/SET_API_STATUS`;
export const SET_API_ERROR = `${MODULE_API}/SET_API_ERROR`;
export const SET_API_RESPONSE = `${MODULE_API}/SET_API_RESPONSE`;
