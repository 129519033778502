import 'styles/globals.css';
import 'styles/fonts.scss';
import 'styles/overrideStyles.scss';
import 'intersection-observer';
import { FC } from 'react';
import Modal from 'react-modal';
import { UniformContext } from '@uniformdev/context-react';
import { UniformAppProps } from '@uniformdev/context-next';

import { wrapper } from 'app-redux/store/store';
import { getUniformContext } from 'lib/uniform.service';
import { MainContainer } from 'components/dynamic';

const context = getUniformContext({
  defaultConsent: true,
});

Modal.setAppElement('#__next');
const MyApp: FC<UniformAppProps> = ({
  Component,
  serverUniformContext,
}) => (
  // @ts-ignore
  <UniformContext context={serverUniformContext ?? context}>
    <MainContainer>
      {Component}
    </MainContainer>
  </UniformContext>
);

export default wrapper.withRedux(MyApp);
