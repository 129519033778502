import type { HoroscopeTypeSlug, ZodiacSign } from 'types/objectTypes';

export const LINK_TYPE = 'Link';
export const NAV_ITEM_TYPE = 'NavItem';
export const TEXT_TYPE = 'Text';
export const EMAIL = 'email';
export const PASSWORD = 'password';
export const USER = 'user';
export const NC_OFFER_SLIDER_INTERVAL_DELAY = 7000;
export const FAVORITE_PSYCHIC_HINT_HIDING = 7000;
export const SCROLL_EVENT = 'scroll';
export const MOUSEDOWN_EVENT = 'mousedown';
export const RESIZE = 'resize';
export const SEARCH_BOX_DEBOUNCE_DELAY = 200;
export const ONE_HUNDRED_DAYS = 100 * 24 * 3600;
export const MINUTE_IN_SECONDS = 60;
export const SESSION_COOKIE_NAME = 'auth';
export const SET_COOKIE_HEADER = 'set-cookie';
export const LABEL_COOKIE_NAME = 'label';
export const CALLBACK_URL = 'next-callbackUrl';
export const GDPR_COOKIE = 'GDPRCookie';
export const USER_AGENT = 'user-agent';
export const WEBP_TYPE = 'image/webp';
export const SVG_TYPE = 'image/svg';
export const SVG_XML_TYPE = 'image/svg+xml';
export const WEBP_FORMAT = 'webp';
export const SOURCE_NODE_NAME = 'SOURCE';
export const IMG_NODE_NAME = 'IMG';
export const MIN_ALLOWED_AGE = 18;
export const DEFAULT_SELECT_OPTION = 'default';
export const HEAD_SCRIPTS = 'headScripts';
export const CLOSING_SCRIPTS = 'closingScripts';
export const DATA_NAVIGATION_ATTRIBUTE_NAME = process.env.NEXT_PUBLIC_DATA_NAVIGATION_GA || 'data-navigation';
export const ROUTE_CHANGE_START = 'routeChangeStart';
export const ROUTE_CHANGE_COMPLETE = 'routeChangeComplete';
export const EXTERNAL_WEBP_IMAGE = 'external';
export const SLASH_SYMBOL_LENGTH = 1;
export const IMAGE_SIZE_RATIO = 0.5;
export const TEXTAREA_MAX_LENGTH = 5000;
export const COLLAPSE = 'collapse';
export const EXPAND = 'expand';
export const DEFAULT_SRC = '#';
export const MAX_CONTENTFUL_IMAGE_SIDE_SIZE = 4000;
export const YOUTUBE = 'youtube';
export const BUTTON = 'button';
export const IS_CLIENT_SIDE = Boolean(typeof window !== 'undefined');
export const AWS_BUCKET_NAME = 'cp-cms';
export const NEW_LINE_SIGN = '   ';
export const USA_COUNTRY_NAME = 'USA';

/* cookie */
export const ASK_ACCOUNT_INFO = 'askAccountInfo';
export const USER_TIME_ZONE = 'userTimeZone';
export const ENHANCED_PRICING = 'enhancedpricing';
export const CHAT_AGENT_PERSISTANCE_COOKIE = 'chatAgentPersistance';
export const MAS_PHONE_NUMBER = 'MASPhoneNumber';
export const NC_PROMOCODE = 'ncpromocode';
export const IS_FIXED_BANNER_SEEN = 'isFixedBannerSeen';
export const OAUTH_REDIRECT_URL = 'oAuthRedirectUrl';
export const TEMP_REFERRER_COOKIE = '_referrer';
export const SORT_ID_COOKIE = 'PsychicCustomSortId';

/* Sentry */
export const TRACES_SAMPLE_RATE = 1.0;
export const MAX_BREADCRUMBS = 50;
export const NORMALIZE_DEPTH = 10;

/* tags */
export const BR_TAG = '<br>';
export const LINK_TAG_NAME = 'a';

/* Horoscopes */
export const HOROSCOPE_PAGE_MOBILE_ICON_SIDE_SIZE = 65;
export const HOROSCOPE_PAGE_DESKTOP_ICON_SIDE_SIZE = 54;

/* Extensions */
export const CSS_EXTENSION = '.css';
export const JSON_EXTENSION = '.json';

/* Hardcoded slugs */
export const CONTACT_US_SLUG = 'contact';
export const HOMEPAGE_SLUG = 'homepage';
export const EC_HOMEPAGE_SLUG = 'echomepage';
export const ABOUT_SLUG = 'about';
export const HELP_TELEPHONE_SLUG = 'help-telephone';
export const PAYMENT_SETTINGS = 'payment-settings';
export const MY_INBOX = 'my-inbox';

/* Agent Chat */
export const AGENT_CHAT_IFRAME_ID = 'ctm-frame-0';

/* Array constants */
export const HOROSCOPES_TYPES: HoroscopeTypeSlug = ['daily', 'weekly-love', 'monthly', 'yearly', 'weekend', 'monthly-money'];
export const ZODIAC_SIGNS: Array<ZodiacSign> = ['aries', 'taurus', 'gemini', 'cancer', 'leo', 'virgo', 'libra', 'scorpio', 'sagittarius', 'capricorn', 'aquarius', 'pisces'];

/* Dynamic routs */
export const HOROSCOPE_SIGN_NAME = 'signName';

/* Exponea */
export const EXPONEA_RECOMMENDATION_SIZE_MULTIPLIER = 10;

/* Url */
export const SIGN_IN_URL = `${process.env.NEXT_PUBLIC_BASE_SERVER_URL}/sign-in`;
export const SIGN_UP_URL = `${process.env.NEXT_PUBLIC_BASE_SERVER_URL}/ask-question-signup`;
export const SIGN_UP_SECOND_STEP_URL = `${process.env.NEXT_PUBLIC_BASE_SERVER_URL}/ask-question-checkout`;
export const NC_FUNNEL_SIGN_UP_URL = `${process.env.NEXT_PUBLIC_BASE_SERVER_URL}/ncsignup`;

export const LOWERCASE_SORT_ID_KEY = 'sortid';
